import React from 'react';
import { Link } from '@reach/router';
import { useQuery, useMutation, queryCache } from 'react-query';

import { getCategories, deleteCategory } from 'actions';

const CategoryRow = ({ category }) => {
  const [mutate, { isLoading }] = useMutation(deleteCategory, {
    onSuccess: () => {
      queryCache.refetchQueries('categories');
    },
  });

  return (
    <tr>
      <td>{category.name}</td>
      <td>
        <div className="buttons is-right">
          <Link className="button is-primary" to={`${category.id}/edit`}>
            <span className="icon">
              <i className="material-icons">edit</i>
            </span>
          </Link>
          <div className="dropdown is-right is-hoverable">
            <div className="dropdown-trigger">
              <button
                className="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu4"
              >
                <span className="icon">
                  <i className="material-icons">more_horiz</i>
                </span>
              </button>
            </div>
            <div className="dropdown-menu">
              <div className="dropdown-content">
                <div
                  className="dropdown-item has-background-danger has-text-white"
                  onClick={async () => {
                    if (isLoading) return;

                    try {
                      await mutate(category.id);
                    } catch (error) {}
                  }}
                >
                  Supprimer
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

const ListCategories = () => {
  const { isLoading, data } = useQuery('categories', getCategories);

  return (
    <section className="section">
      <div className="container is-fluid is-fullhd">
        <div className="level">
          <div className="level-left">
            <h1 className="title">Liste des categories</h1>
          </div>
          <div className="level-right">
            <div className="buttons">
              <Link className="button is-primary" to="/categories/creer">
                Créer catégorie
              </Link>
            </div>
          </div>
        </div>
        <div className="box">
          {isLoading && <p>Chargement…</p>}
          {!isLoading && data && (
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th className="has-text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((category, index) => (
                  <CategoryRow category={category} key={index} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default ListCategories;
