import React, { lazy, Suspense } from 'react';
import { Router, Redirect } from '@reach/router';
import { ReactQueryConfigProvider } from 'react-query';

import Info from 'pages/Info/Info';
import { getSession } from 'utils';
import Login from 'pages/Login/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import { Public, Private, ErrorBoundary } from 'components';
import { ListCategories, CreateCategory, EditCategory } from 'pages/Categories';

const EditProduct = lazy(() => import('pages/Products/Edit'));
const ListProducts = lazy(() => import('pages/Products/List'));
const CreateProduct = lazy(() => import('pages/Products/Create'));
const EditClient = lazy(() => import('pages/Clients/Edit'));
const ListClients = lazy(() => import('pages/Clients/List'));
const CreateClient = lazy(() => import('pages/Clients/Create'));
const ListOrders = lazy(() => import('pages/Orders/List'));
const CreateOrder = lazy(() => import('pages/Orders/Create'));
const EditOrder = lazy(() => import('pages/Orders/Edit'));
const EditStore = lazy(() => import('pages/Store/Edit'));
const SummaryOrders = lazy(() => import('pages/Orders/Summary'));
const BillOrder = lazy(() => import('pages/Orders/Bill'));
// const Board = lazy(() => import('pages/Workflow/Board'));

const PrivateRoute = ({ component: Component, ...props }) => {
  const session = getSession();

  return session ? (
    <Private>
      <Component {...props} />
    </Private>
  ) : (
    <Redirect to="/login" noThrow />
  );
};

const PublicRoute = ({ component: Component, ...props }) => {
  const session = getSession();

  return !session ? (
    <Public>
      <Component {...props} />
    </Public>
  ) : (
    <Redirect to="/" noThrow />
  );
};

const App = () => {
  return (
    <ErrorBoundary>
      <ReactQueryConfigProvider config={{ mutations: { throwOnError: true } }}>
        <Suspense fallback={null}>
          <Router>
            <PublicRoute component={Login} path="/login" />
            <Info path="/info/:orderReferenceKey" />
            <PrivateRoute component={Dashboard} path="/" />
            {/* <PrivateRoute component={Board} path="/suivi" /> */}
            <PrivateRoute component={ListCategories} path="/categories" />
            <PrivateRoute component={CreateCategory} path="/categories/creer" />
            <PrivateRoute
              component={EditCategory}
              path="/categories/:categoryId/edit"
            />
            <PrivateRoute component={ListProducts} path="/produits" />
            <PrivateRoute component={CreateProduct} path="/produits/creer" />
            <PrivateRoute
              component={EditProduct}
              path="/produits/:productId/edit"
            />
            <PrivateRoute component={ListClients} path="/clients" />
            <PrivateRoute component={CreateClient} path="/clients/creer" />
            <PrivateRoute
              component={EditClient}
              path="/clients/:clientId/edit"
            />
            <PrivateRoute component={ListOrders} path="/commandes" />
            <PrivateRoute component={SummaryOrders} path="/commandes/recap" />
            <PrivateRoute component={CreateOrder} path="/commandes/creer" />
            <PrivateRoute
              component={EditOrder}
              path="/commandes/:orderId/edit"
            />
            <PrivateRoute
              component={BillOrder}
              path="/commandes/:orderId/bill"
            />
            <PrivateRoute component={EditStore} path="/ma-boutique" />
          </Router>
        </Suspense>
      </ReactQueryConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
