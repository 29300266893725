import React from 'react';
import { Field } from 'formik';

import ErrorMessage from './ErrorMessage';

const Time = ({ label, placeholder = '00:00', name, required = false }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <Field
          className="input"
          name={name}
          pattern="[0-9]{2}:[0-9]{2}"
          placeholder={placeholder}
          required={required}
          type="time"
        />
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Time;
