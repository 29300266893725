import React from 'react';
import { DateTime } from 'luxon';
import fr from 'date-fns/locale/fr';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDate = ({ value, onClick }) => (
  <button className="button is-primary" onClick={onClick}>
    <span className="icon">
      <i className="material-icons">calendar_today</i>
    </span>
    <span>{value}</span>
  </button>
);

export const InlineDatePicker = ({ date, onChange }) => {
  return (
    <DatePicker
      locale={fr}
      todayButton="Aujourd'hui"
      dateFormat="dd MMMM yyyy"
      selected={DateTime.fromISO(date).toJSDate()}
      onChange={onChange}
      customInput={<CustomDate />}
      customInputRef="inline-date-picker"
      withPortal
    />
  );
};
