import React from 'react';
import { Field } from 'formik';
import { DateTime } from 'luxon';
import fr from 'date-fns/locale/fr';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ErrorMessage from './ErrorMessage';

const CustomDate = ({ value, onClick }) => (
  <button className="button" type="button" onClick={onClick}>
    {value || 'Séléctionner une date'}
  </button>
);

const Datepicker = ({ label, placeholder = '', name }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <Field name={name} placeholder={placeholder}>
          {({ field, form }) => (
            <DatePicker
              className="input"
              locale={fr}
              dateFormat="dd MMMM yyyy"
              selected={
                field.value ? DateTime.fromISO(field.value).toJSDate() : null
              }
              onChange={value => {
                form.setFieldValue(
                  field.name,
                  DateTime.fromJSDate(value).toISODate(),
                );
              }}
              customInput={<CustomDate />}
              customInputRef="form-date-picker"
              withPortal
              placeholderText="Séléctionner une date"
            />
          )}
        </Field>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Datepicker;
