import { request } from 'utils';

export const getCategories = () =>
  request({
    method: 'GET',
    url: '/api/categories',
  });

export const getCategory = id => () =>
  request({
    method: 'GET',
    url: `/api/categories/${id}`,
  });

export const postCategory = data =>
  request({
    method: 'POST',
    url: `/api/categories`,
    data,
  });

export const putCategory = data =>
  request({
    method: 'PUT',
    url: `/api/categories/${data.id}`,
    data,
  });

export const deleteCategory = id =>
  request({
    method: 'DELETE',
    url: `/api/categories/${id}`,
  });
