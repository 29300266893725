import { Field } from 'formik';
import React, { useState } from 'react';

import './styles/Numeric.css';
import { CustomModal } from '../Modal';

const FORMATS = {
  price: { conversion: 100, decimal: 2, symbol: '€' },
  weight: { conversion: 1000, decimal: 3, symbol: 'Kg' },
  integer: { conversion: 1, decimal: 0, symbol: '123' },
};

const Numeric = ({
  label,
  placeholder = '',
  required,
  format,
  field,
  form,
  callback = null,
  hideSymbol = false,
}) => {
  const formatType = FORMATS[format];
  const conversion = formatType.conversion;
  const decimal = formatType.decimal;
  const [isOpen, setIsOpen] = useState(false);
  const [unformattedValue, setUnformattedValue] = useState('');

  const tap = number => () => {
    const value = `${unformattedValue}${number}`;
    if (value.length > 8) return;

    setUnformattedValue(value);
    form.setFieldValue(field.name, Number(value / conversion).toFixed(decimal));
  };

  return (
    <>
      <label className="label">{label}</label>
      <div className="field has-addons">
        {!hideSymbol && (
          <div className="control">
            <button className="button is-static" type="button">
              {formatType.symbol}
            </button>
          </div>
        )}
        <div className="control">
          <input
            className="input"
            placeholder={placeholder}
            type="number"
            readOnly
            {...field}
          />
        </div>
        <div className="control">
          <button
            className="button is-primary"
            onClick={() => {
              setIsOpen(true);
            }}
            type="button"
          >
            <span className="icon">
              <i className="material-icons">create</i>
            </span>
          </button>
        </div>
      </div>
      <CustomModal isOpen={isOpen} closeModal={() => setIsOpen(false)} center>
        <div className="modal-content modal--auto">
          <div className="numeric__keyboard">
            <div className="numeric__value">
              <span>{field.value || 0}</span>
            </div>
            <div className="keyboard__container">
              <div className="keyboard__cell" onClick={tap(1)}>
                1
              </div>
              <div
                className="keyboard__cell keyboard__cell--bordered"
                onClick={tap(2)}
              >
                2
              </div>
              <div className="keyboard__cell" onClick={tap(3)}>
                3
              </div>
              <div className="keyboard__cell" onClick={tap(4)}>
                4
              </div>
              <div
                className="keyboard__cell keyboard__cell--bordered"
                onClick={tap(5)}
              >
                5
              </div>
              <div className="keyboard__cell" onClick={tap(6)}>
                6
              </div>
              <div className="keyboard__cell" onClick={tap(7)}>
                7
              </div>
              <div
                className="keyboard__cell keyboard__cell--bordered"
                onClick={tap(8)}
              >
                8
              </div>
              <div className="keyboard__cell" onClick={tap(9)}>
                9
              </div>
              <div
                className="keyboard__cell"
                onClick={() => {
                  setUnformattedValue(0);
                  form.setFieldValue(field.name, 0);
                }}
              >
                C
              </div>
              <div
                className="keyboard__cell keyboard__cell--bordered"
                onClick={tap(0)}
              >
                0
              </div>
              <div
                className="keyboard__cell has-background-success has-text-white"
                onClick={() => {
                  setIsOpen(false);
                  callback && callback();
                }}
              >
                Valider
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

const NumericField = props => <Field component={Numeric} {...props} />;

export default NumericField;
