import React from 'react';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';

import { getInfoOrder } from 'actions';

const ORDER_STATUSES = {
  created: 'En validation',
  validated: 'En preparation',
  prepared: 'Commande prête',
  collected: 'Commande récupérée',
};

const Info = ({ orderReferenceKey }) => {
  const { data, isLoading, error } = useQuery(
    ['info', { orderReferenceKey }],
    getInfoOrder(orderReferenceKey),
  );

  return (
    <section className="section">
      <div className="box container">
        {isLoading && (
          <div className="has-text-centered">
            <p>Chargement de la commande…</p>
          </div>
        )}
        {error && (
          <div className="has-text-centered">
            <p>Commande introuvable</p>
          </div>
        )}
        {data && (
          <>
            <div className="has-text-centered">
              <h1 className="title">{data.store.name}</h1>
              {data.store.address && <p>{data.store.address}</p>}
              {data.store.phone && (
                <a href={`tel:${data.store.phone}`}>{data.store.phone}</a>
              )}
            </div>
            <div className="tags is-centered">
              <span className="tag is-small is-info">
                {ORDER_STATUSES[data.status]}
              </span>
            </div>
            <div className="columns">
              <div className="column">
                <h2 className="subtitle">Votre commande</h2>
                <hr />
                <div className="content">
                  <p>Référence: {data.reference}</p>
                  <p>
                    Date:{' '}
                    {DateTime.fromISO(data.date).toLocaleString(
                      DateTime.DATETIME_MED,
                    )}
                  </p>
                  <h3 className="subtitle is-5">Client</h3>
                  <p>{data.client.fullname}</p>
                  <p>Email: {data.client.email || '-'}</p>
                  <p>Portable: {data.client.mobile_phone || '-'}</p>
                  <p>Fixe: {data.client.landline_phone || '-'}</p>
                </div>
              </div>
              <div className="column">
                <h2 className="subtitle">Produits</h2>
                <hr />
                <div className="content">
                  {data.items.length > 0 ? (
                    <ul>
                      {data.items.map((item, index) => (
                        <li key={index}>
                          {item.weight ? `${item.weight} Kg /` : ''}
                          {item.pieces ? `${item.pieces} pcs /` : ''}
                          {item.people ? `${item.people} pers /` : ''}
                          &nbsp;{item.product.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>Pas d'elements dans la commande</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Info;
