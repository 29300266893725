import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './ErrorMessage';

const Checkbox = ({ label, name, ...props }) => {
  return (
    <div className="field">
      <label className="checkbox">
        <Field name={name} type="checkbox" {...props} />
        &nbsp;{label}
      </label>
      <ErrorMessage name={name} />
    </div>
  );
};

export default Checkbox;
