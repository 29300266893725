import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

Modal.setAppElement('#root');

export const CustomModal = ({
  children,
  isOpen,
  closeModal,
  center = false,
  fixed = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal"
      portalClassName={classNames('modal', {
        'is-active': isOpen,
      })}
      overlayClassName={classNames('modal__overlay', {
        'modal--center': center,
      })}
      className={classNames('modalContent', { modal__container: fixed })}
      bodyOpenClassName="ReactModal__Body--open"
      htmlOpenClassName="ReactModal__Html--open"
      role="dialog"
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </Modal>
  );
};
