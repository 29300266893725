import React from 'react';
import { Field } from 'formik';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';

import ErrorMessage from './ErrorMessage';

const Phone = ({ label, placeholder = '', name, required = false }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <Field name={name}>
          {({ field, form }) => (
            <PhoneInput
              country="fr"
              regions="europe"
              preferredCountries={['fr']}
              countryCodeEditable={false}
              localization={fr}
              value={field.value || ''}
              inputClass="input"
              onChange={(phone, countryData) => {
                if (phone.substring(1) === countryData.dialCode) {
                  form.setFieldValue(field.name, '');
                  return;
                }
                form.setFieldValue(field.name, `+${phone}`);
              }}
              inputProps={{ required, id: field.name }}
            />
          )}
        </Field>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Phone;
