import { request } from 'utils';

export const postOrderItem = data =>
  request({
    method: 'POST',
    url: `/api/order-items`,
    data,
  });

export const putOrderItem = data =>
  request({
    method: 'PUT',
    url: `/api/order-items/${data.id}`,
    data,
  });

export const deleteOrderItem = id =>
  request({
    method: 'DELETE',
    url: `/api/order-items/${id}`,
  });
