import { request } from 'utils';

export const getStore = () =>
  request({
    method: 'GET',
    url: '/api/store',
  });

export const putStore = data =>
  request({
    method: 'PUT',
    url: '/api/store',
    data,
  });
