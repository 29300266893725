import React from 'react';
import ReactDOM from 'react-dom';
import { Settings } from 'luxon';
import * as Sentry from '@sentry/browser';

import 'bulma/index.scss';
import './index.css';
import App from './App';
import { config } from 'utils';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ReactQueryDevtools } from 'react-query-devtools';

Settings.defaultLocale = 'fr';

if (config.env !== 'local') {
  Sentry.init({
    dsn: config.sentry,
    environment: config.env,
    release: `${config.name}@${config.version}`,
  });
}

ReactDOM.render(
  <>
    {config.env === 'local' && <ReactQueryDevtools />}
    <App />
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const Notif = () => (
      <div class="notification is-success refresh__pwa has-text-centered">
        <p>Nouvelle version disponible ! Mettre à jour</p>
        <button
          className="button"
          onClick={() => {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload(true);
          }}
        >
          Rafraîchir
        </button>
      </div>
    );
    ReactDOM.render(<Notif />, document.getElementById('sw-refresh'));
  },
});
