import React from 'react';
import { ErrorMessage as FormikError } from 'formik';

const ErrorMessage = ({ name }) => {
  return (
    <FormikError name={name}>
      {msg => <p className="help is-danger">{msg}</p>}
    </FormikError>
  );
};

export default ErrorMessage;
