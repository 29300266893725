import React from 'react';

import './Public.css';

const Public = ({ children }) => {
  return (
    <main className="public">
      <div className="box">
        <h1 className="title is-1">Simplartisan</h1>
        {children}
      </div>
    </main>
  );
};

export default Public;
