import React from 'react';
import { Field } from 'formik';

const Select = ({
  name,
  label,
  options = [],
  placeholder = 'Séléctioner',
  required = false,
}) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="select">
        <Field name={name} component="select" required={required}>
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Field>
      </div>
    </div>
  );
};

export default Select;
