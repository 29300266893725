import { request } from 'utils';

export const getOrders = (date = '') => () =>
  request({ method: 'GET', url: `/api/orders?date=${date}` });

export const searchOrders = (search = '') =>
  request({
    method: 'GET',
    url: `/api/orders?search=${encodeURIComponent(search)}`,
  });

export const getOrder = (id) => () =>
  request({ method: 'GET', url: `/api/orders/${id}` });

export const postOrder = (data) =>
  request({ method: 'POST', url: `/api/orders`, data });

export const putOrder = (data) =>
  request({ method: 'PUT', url: `/api/orders/${data.id}`, data });

export const deleteOrder = (id) =>
  request({ method: 'DELETE', url: `/api/orders/${id}` });

export const pdfOrder = (id) =>
  request({ method: 'GET', url: `/api/orders/${id}/pdf` });

export const billOrder = (data) =>
  request({ method: 'POST', url: `/api/orders/${data.id}/bill`, data });

export const pdfOrders = (date) =>
  request({ method: 'GET', url: `/api/orders/pdf/${date}` });

export const getSummary = (startDate, endDate) => () =>
  request({
    method: 'GET',
    url: `/api/orders/summary/${startDate}/${endDate}`,
  });

export const sendEmail = (id) => () =>
  request({ method: 'POST', url: `/api/orders/${id}/email` });

export const sendSms = (id) => () =>
  request({ method: 'POST', url: `/api/orders/${id}/sms` });

export const getInfoOrder = (referenceKey) => () =>
  request({ method: 'GET', url: `/api/info/${referenceKey}` });
