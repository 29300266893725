import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import ErrorImage from 'images/error.svg';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <section className="section">
          <div className="container content has-text-centered">
            <figure className="image" style={{ width: '40%', margin: 'auto' }}>
              <img src={ErrorImage} alt="Erreur" />
            </figure>
            <p>
              Une erreur est survenue, nous faisons le maximum pour corriger
              cela !
            </p>
            <a href="/" className="button">
              Rafraîchir
            </a>
          </div>
        </section>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}
