import classname from 'classnames';
import { Link, navigate } from '@reach/router';
import React, { useState, useEffect } from 'react';

import './Private.css';
import { clearSession, config, Notifier } from 'utils';

const Notification = ({ notif }) => {
  const notifier = Notifier.useContainer();

  useEffect(() => {
    const autoRemove = setTimeout(() => {
      notifier.remove(notif.id);
    }, 5000);

    return () => {
      clearTimeout(autoRemove);
    };
  });

  return (
    <div
      className={classname('notification has-text-right', {
        'is-success': notif.type === 'success',
        'is-danger': notif.type === 'error',
      })}
    >
      {notif.message}
    </div>
  );
};

const Notifications = () => {
  const notifier = Notifier.useContainer();

  return (
    <div className="notifications">
      {notifier.notifications.map((notif, index) => (
        <Notification key={`notif-${notif.id}`} notif={notif} />
      ))}
    </div>
  );
};

const isActiveHome = ({ isCurrent }) => {
  return isCurrent
    ? { className: 'navbar-item is-active' }
    : { className: 'navbar-item' };
};

const isActive = ({ isCurrent, isPartiallyCurrent }) => {
  return isCurrent || isPartiallyCurrent
    ? { className: 'navbar-item is-active' }
    : { className: 'navbar-item' };
};

const Private = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <Notifier.Provider>
        <header>
          <nav
            className="navbar is-white"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                Simplartisan
              </Link>
              {/* eslint-disable-next-line */}
              <a
                role="button"
                className={classname('navbar-burger burger', {
                  'is-active': menuIsOpen,
                })}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen);
                }}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </a>
            </div>

            <div
              className={classname('navbar-menu', { 'is-active': menuIsOpen })}
            >
              <div className="navbar-start">
                <Link getProps={isActiveHome} to="/">
                  Tableau de bord
                </Link>
                <Link getProps={isActive} to="/commandes">
                  Commandes
                </Link>
                <Link getProps={isActive} to="/produits">
                  Produits
                </Link>
                <Link getProps={isActive} to="/clients">
                  Clients
                </Link>
                <Link getProps={isActive} to="/categories">
                  Catégories
                </Link>
              </div>
              <div className="navbar-end">
                <div className="navbar-item has-dropdown is-hoverable">
                  <span className="navbar-link">Mon compte</span>
                  <div className="navbar-dropdown is-right">
                    <Link className="navbar-item" to="/ma-boutique">
                      Ma boutique
                    </Link>
                    <span
                      className="navbar-item"
                      onClick={() => {
                        clearSession();
                        navigate('/login');
                      }}
                    >
                      Déconnexion
                    </span>
                    <hr className="navbar-divider" />
                    <div className="navbar-item">Version {config.version}</div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <main>{children}</main>
        <Notifications />
      </Notifier.Provider>
    </>
  );
};

export default Private;
