import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useNotifier = () => {
  const [notifications, setNotifications] = useState([]);

  const add = (notif) =>
    setNotifications([...notifications, { id: uuidv4(), ...notif }]);

  const remove = (id) =>
    setNotifications(notifications.filter((notif) => notif.id !== id));

  return { notifications, add, remove };
};

export const Notifier = createContainer(useNotifier);
