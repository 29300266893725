import React from 'react';
import { Formik, Form } from 'formik';

import { Input, Button } from 'components';

const FormCategory = ({ initialValues, handleSubmit, loading }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {() => {
        return (
          <Form>
            <Input
              name="name"
              label="Nom"
              placeholder="Nom type de produit"
              required
            />
            <div className="buttons">
              <Button primary loading={loading} type="submit">
                Enregistrer
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormCategory;
