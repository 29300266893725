import React from 'react';
import { get } from 'lodash';
import { Link } from '@reach/router';
import { useMutation } from 'react-query';

import FormCategory from './Form';
import { postCategory } from 'actions';

const CreateCategory = ({ navigate }) => {
  const [mutate, { isLoading }] = useMutation(postCategory);

  return (
    <section className="section">
      <div className="container is-fluid is-fullhd">
        <div className="level">
          <div className="level-left">
            <h1 className="title is-1">Nouvelle catégorie</h1>
          </div>
          <div className="level-right">
            <div className="buttons">
              <Link className="button" to="/categories">
                Liste catégories
              </Link>
            </div>
          </div>
        </div>
        <div className="box">
          <FormCategory
            loading={isLoading}
            initialValues={{ name: '' }}
            handleSubmit={async (values, { setErrors }) => {
              try {
                await mutate(values);
                navigate('/categories');
              } catch (error) {
                setErrors(get(error, 'response.data.errors', {}));
              }
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default CreateCategory;
