import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './ErrorMessage';

const Radio = ({ label, name, required = false, options = [] }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        {options.map((option, index) => (
          <label className="radio" key={index}>
            <Field name={name}>
              {({ field, form }) => (
                <input
                  type="radio"
                  name={field.name}
                  required={required}
                  checked={option.value === field.value}
                  {...field}
                  value={option.value}
                />
              )}
            </Field>
            &nbsp;{option.label}
          </label>
        ))}
      </div>
      <ErrorMessage name={name} />
    </div>
  );
};

export default Radio;
