import { request } from 'utils';

export const getProducts = () =>
  request({
    method: 'GET',
    url: '/api/products',
  });

export const getProduct = id => () =>
  request({
    method: 'GET',
    url: `/api/products/${id}`,
  });

export const postProduct = data =>
  request({
    method: 'POST',
    url: `/api/products`,
    data,
  });

export const putProduct = data =>
  request({
    method: 'PUT',
    url: `/api/products/${data.id}`,
    data,
  });

export const deleteProduct = id =>
  request({
    method: 'DELETE',
    url: `/api/products/${id}`,
  });
