import { request } from 'utils';

export const getClients = () =>
  request({
    method: 'GET',
    url: '/api/clients',
  });

export const getClient = id => () =>
  request({
    method: 'GET',
    url: `/api/clients/${id}`,
  });

export const postClient = data =>
  request({
    method: 'POST',
    url: '/api/clients',
    data,
  });

export const putClient = data =>
  request({
    method: 'PUT',
    url: `/api/clients/${data.id}`,
    data,
  });

export const deleteClient = id =>
  request({
    method: 'DELETE',
    url: `/api/clients/${id}`,
  });
