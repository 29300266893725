import axios from 'axios';
import { navigate } from '@reach/router';

import { config } from './config';
import { getSession, setSession, clearSession } from './session';

export const client = axios.create({
  baseURL: config.api,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const request = (query) => {
  const session = getSession();

  return client
    .request({
      headers: {
        Authorization: session ? `Bearer ${session.access_token}` : '',
      },
      ...query,
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        client
          .request({
            url: '/oauth/token',
            method: 'POST',
            data: {
              refresh_token: session.refresh_token,
              grant_type: 'refresh_token',
            },
          })
          .then((res) => {
            setSession(res.data);
          })
          .catch((err) => {
            clearSession();
            navigate('/login');
          });
      }
      throw err;
    });
};
