import React from 'react';
import { get } from 'lodash';
import { Link } from '@reach/router';
import { useMutation, useQuery } from 'react-query';

import FormCategory from './Form';
import { getCategory, putCategory } from 'actions';

const EditCategory = ({ navigate, categoryId }) => {
  const { data, isLoading: isLoadingGet } = useQuery(
    ['category', { categoryId }],
    getCategory(categoryId),
  );
  const [mutate, { isLoading: isLoadingPut }] = useMutation(putCategory);

  return (
    <section className="section">
      <div className="container is-fluid is-fullhd">
        <div className="level">
          <div className="level-left">
            <h1 className="title is-1">Editer catégorie</h1>
          </div>
          <div className="level-right">
            <div className="buttons">
              <Link className="button" to="/categories">
                Liste catégories
              </Link>
            </div>
          </div>
        </div>
        <div className="box">
          {isLoadingGet && <p>Chargement…</p>}
          {!isLoadingGet && data && (
            <FormCategory
              loading={isLoadingPut}
              initialValues={data}
              handleSubmit={async (values, { setErrors }) => {
                try {
                  await mutate(values, {
                    updateQuery: ['category', { categoryId }],
                  });
                  navigate('/categories');
                } catch (error) {
                  setErrors(get(error, 'response.data.errors', {}));
                }
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default EditCategory;
