import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './ErrorMessage';

const Textarea = ({ label, name, placeholder = '', ...props }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <Field
          className="textarea"
          name={name}
          placeholder={placeholder}
          component="textarea"
          {...props}
        />
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Textarea;
