import React from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';

import { login } from 'actions';
import { setSession } from 'utils';
import { Input, Button, ErrorMessage } from 'components';

const Login = ({ navigate }) => {
  const [mutate, { isLoading }] = useMutation(login);

  return (
    <>
      <Formik
        initialValues={{ username: '', password: '', grant_type: 'password' }}
        onSubmit={async (values, { setFieldError }) => {
          try {
            const data = await mutate(values);
            setSession(data);
            navigate('/');
          } catch (error) {
            setFieldError('password', 'Les identifiants sont incorrects');
          }
        }}
      >
        {() => {
          return (
            <Form>
              <Input
                label="Email"
                name="username"
                type="email"
                placeholder="Email"
                required
              />
              <Input
                label="Mot de passe"
                name="password"
                type="password"
                placeholder="Mot de passe"
                required
              />
              <ErrorMessage name="global" />
              <div className="buttons is-centered">
                <Button primary loading={isLoading} type="submit">
                  Se connecter
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Login;
