import React from 'react';
import { Field } from 'formik';
import ErrorMessage from './ErrorMessage';

const Input = ({ label, placeholder = '', name, type = 'text', ...props }) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <Field
          className="input"
          name={name}
          placeholder={placeholder}
          type={type}
          {...props}
        />
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

export default Input;
