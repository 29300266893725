import React from 'react';
import classnames from 'classnames';

export const Button = ({
  children,
  loading = false,
  type = 'button',
  primary = false,
  danger = false,
  onClick = null,
}) => {
  return (
    <button
      className={classnames('button', {
        'is-loading': loading,
        'is-primary': primary,
        'is-danger': danger,
      })}
      disabled={loading}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
